<template>
	<div class="bottom-container">
		<el-row :gutter="24" style="margin: 0;padding: 0;">
			<el-col :span="20" :offset="2">
				<div class="flex-row-box top-box">
					<div class="logo flex-row-box">
						<img src="@/assets/image/icon-logo.png">
						<span>创农科技</span>
					</div>
					<div class="tel flex-row-box">
						<img src="../assets/image/icon-phone.png" />
						<span>0551-63638251</span>
					</div>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="24" style="margin: 0;padding: 0;">
			<el-col :span="20" :offset="2">
				<div class="center-box">
					<div class="des">
						<div class="menu">
							<span v-for="(item,index) in menulist" :key="index" class="menu-item"
								@click="handleClick(index)">{{item}}</span>
						</div>
						<div class="contact-box">
							<div class="loc flex-row-box ">
								<img src="@/assets/image/icon-loc.png" />
								<span>合肥市高新区黄山路602号合肥国家大学科技园B203</span>
							</div>
							<div class="mail flex-row-box ">
								<img src="@/assets/image/icon-mail.png" />
								<span>chuangnong@163.com</span>
							</div>
							<div class="tel flex-row-box ">
								<img src="@/assets/image/icon-mobile.png" />
								<span>徐经理：18356020681</span>
							</div>
						</div>
					</div>
					<div class="img-box">
						<img src="@/assets/image/QRcode.png" />
						<span>公众号</span>
					</div>
				</div>
			</el-col>
		</el-row>

		<el-row :gutter="24" style="margin: 0;padding: 0;">
			<el-col :span="20" :offset="2">
				<div class="flex-row-box bottom-box">
					<span>chuangnong © 2021</span>
					<!-- <span>皖ICP备202100*****号-1 皖公网安备 310********2 号</span> -->
					<span>
						<a style="text-decoration:none" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">皖ICP备2021015424号</a>
						
					</span>

				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: 'bottomBar',
		data() {
			return {
				menulist: ['S级小型种植机', 'M型中型种植机', 'L型大型种植机', '集装箱植物工厂', '植物工厂', '配件'],
				currentIndex: 0
			}
		},
		methods: {
			handleClick(val) {
				if (val == 0) {
					this.$router.push('/goodS');
				} else {
					console.log('===========');
				}
			}
		}
	}
</script>
<style scoped="scoped" lang="scss">
	.flex-row-box {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.menu-item {
		font-size: 16px;
		color: #FFFFFF;

		&:hover {
			color: #4AB17D;
		}

	}

	@media screen and (max-width:1500px) {
		.menu-item {
			font-size: 14px;
		}
	}



	.bottom-container {
		background-color: #222222;

		.top-box {
			padding: 24px 0 32px 0;
			border-bottom: 1px solid rgba(255, 255, 255, .2);

			.logo span {
				margin-left: 9px;
				font-size: 26px;
				font-weight: bold;
				color: #FFFFFF;
				padding-right: 30px;
				border-right: 1px solid #FFF;
				margin-right: 30px;
			}

			@media screen and (max-width:1500px) {
				.logo img {
					width: 35px;
				}

				.logo span {
					font-size: 20px;
				}
			}

			.tel span {
				margin-left: 14px;
				font-size: 26px;
				color: #AFAFAF;
			}

			@media screen and (max-width:1500px) {
				.tel img {
					width: 30px;
				}

				.tel span {
					font-size: 20px;
				}
			}
		}

		@media screen and (max-width:1900px) {
			.top-box {
				padding: 20px 0;
			}
		}

		.center-box {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.des {
				width: 80%;

				.menu {
					border-bottom: 1px solid rgba(255, 255, 255, .2);
					display: flex;
					justify-content: space-between;
					padding: 31px 0 31px 0;

					span:hover {
						cursor: pointer;
					}
				}

				@media screen and (max-width:1900px) {
					.menu {
						padding: 31px 0;
					}
				}

				.contact-box {
					display: flex;
					justify-content: space-between;
					padding: 42px 0;

					.loc {
						font-size: 16px;
						color: #B0B0B0;

						span {
							margin-left: 10px;
						}
					}

					.mail {
						font-size: 16px;
						color: #AFAFAF;

						span {
							margin-left: 10px;
						}
					}

					.tel {
						font-size: 16px;
						color: #AFAFAF;

						span {
							margin-left: 10px;
						}
					}

					@media screen and (max-width:1500px) {
						.loc {
							font-size: 14px;
						}

						.mail {
							font-size: 14px;
						}

						.tel {
							font-size: 14px;
						}
					}
				}

				@media screen and (max-width:1500px) {
					.contact-box {
						padding: 20px 0;
					}
				}
			}

			.img-box {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				span {
					margin-top: 12px;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

		.bottom-box {
			padding-bottom: 29px;

			span {
				font-size: 14px;
				color: #666666;
			}
		}
	}
</style>
