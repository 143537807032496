<template>
    <div class="cultivation-box">
        <!-- 导航区域 -->
        <custom-nav-top></custom-nav-top>
        <div class="content-box" :style="'height:'+height+'px'">
        </div>
        <!-- 底部区域 -->
        <bottom-bar></bottom-bar>
    </div>
</template>
<script>
import customNavTop from '@/components/custom-nav-top.vue'
import bottomBar from '@/components/bottomBar.vue';
export default {
    components:{
        customNavTop,
        bottomBar
    },
    data(){
        return{
            height:''
        }
    },
    mounted(){
        this.height = document.documentElement.clientHeight
        console.log(this.height)
    },
    methods:{

    }
}
</script>
<style lang="scss">
.cultivation-box{
    .content-box{
        background-image: url('../assets/image/zhinneg.jpg');
        background-repeat: no-repeat;
       background-size: cover;
       background-position: left center;
}
}
@media screen and (max-width:1500px) {
    .cultivation-box{
   
    }
}
</style>